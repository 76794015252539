$red: #e4007a;
$black: #1c191a;
$sigFont: alternate-gothic-no-3-d, sans-serif;
$darkBlack: $black;
$nightWhite: hsla(0,0%,98%,1);
$borderWidth: 3px;
.site-content.kluckinall, .site-content.kokorollburbank {
  transition: background-color 0.3s;
}
.cart.minimized {
  transition: background-color 0.3s;
}
.pages-nav {
  transition: background-color 0.3s;
}
.site-content.kluckinall.dark, .site-content.kokorollburbank.dark {
  color: $nightWhite;
  background-color: $darkBlack;
  transition: background-color 0.3s;
}
.kluckinall.dark, .kokorollburbank.dark {
  .cart.minimized {
    color: $nightWhite;
    background: $darkBlack;
    border-bottom: 2px solid $nightWhite;
  }
  a.page-link {
    font-weight: 400;
    color: $nightWhite;
    border-bottom: $borderWidth solid $darkBlack;
    &:hover, &.active {
      color: $nightWhite;
      border-bottom: $borderWidth solid $red;
    }
  }
  .pages-nav {
    color: $nightWhite;
    background: $darkBlack;
  }
  .quick-links {
    background: $darkBlack;
    color: $nightWhite;
  }
  a.page-link {
    color: $nightWhite;
    border-bottom-color: $darkBlack;
    &:hover, &.active {
      border-bottom-color: $nightWhite;
    }
  }

  .landing-page {
    .text {
      color: $nightWhite;
      background: rgba(0,0,0,0.7);
    }
  }


}
$darkCheckoutPaneColor: #185500;
.dark {
  .menu-story .category-name .text {
    color: $nightWhite;
  }
  .product-wrapper {
    color: $nightWhite;
    background-color: #222;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23bdbdbd' fill-opacity='0.18' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    .product-info {
      color: $nightWhite;
    }
    .panel, .product-modgroup {
      background: #333;
      box-shadow: 0px 2px 4px #0f0f0f;
    }
  }

  .checkout-container {
    .step-number {
      background: $nightWhite;
      color: $darkBlack;
    }
  }
  .actions {
    .button {
      color: $darkBlack;
      font-weight: 500;
    }
  }
  .cart-type-container {
    background: $darkCheckoutPaneColor;
    color: $nightWhite;
  }

  .checkout__cash-payment {
    color: $nightWhite;
    background: $darkCheckoutPaneColor;
  }
  .price-summary {
    color: $nightWhite;
  }
  .switch-button-group, .cart-type-container {
    color: $nightWhite;
    label.selected {
      background: $darkCheckoutPaneColor;
    }
    label.not-selected {
      background: #8bb487;
    }
  }
  // .pick-ready-time {
  //   background: none;
  // }
  // .checkout-tips-section .tip-option-clickable label {
  //   color: black;
  // }
  .place-order-section {
    background: $darkCheckoutPaneColor;
    .place-order-button {
      background: #16b7ff;
    }
  }
  .footer {
    background: #111;
  }
}
